import styled from 'styled-components'

export const CardBase = styled.div`
  background-color: ${props => props.theme.background};
`

export const ViewCardTitle = styled.div`
  color: ${props => props.theme.navigationDark};
  font-size: 20px;
  line-height: 20px;
`

export const SectionedCard = styled.div`
  background-color: ${props => props.theme.background};
  border-radius: 6px;
  padding: 8px 16px !important;
  margin: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
`

export const CardLabel = styled.div`
  height: 19px;
  font-size: 16px;
  font-weight: bold;
  line-height: 19px;
  margin-bottom: 16px;
  margin-top: 6px;
`
