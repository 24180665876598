import React from 'react'
import styled from 'styled-components'

const CardBoxContainer = styled.div`
  height: 65px;
  width: 197px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const CardBoxLabel = styled.div`
  height: 13px;
  color: ${props => props.theme.darkGrey};
  font-size: 11px;
  line-height: 13px;
  margin: 0 7px;
`

const CardBoxText = styled.div`
  height: 18px;
  color: ${props => props.theme.darkGrey};
  font-size: 15px;
  line-height: 18px;
  margin: 5px 7px;
`

const OrderCardBox = ({ label, text }) => (
  <CardBoxContainer>
    <CardBoxLabel>{label}</CardBoxLabel>
    <CardBoxText>{text}</CardBoxText>
  </CardBoxContainer>
)

export default OrderCardBox
