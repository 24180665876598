import { connect } from 'react-redux'
import SlideoutComponent from 'mgr/lib/components/Slideout'
import * as SlideoutActions from 'mgr/orderslideout/actions/SlideoutActions'

const mapStateToProps = state => ({
  isSlideoutOpen: state.slideoutState.isSlideoutOpen,
})

const mapDispatchToProps = dispatch => ({
  onCloseHandler: () => dispatch(SlideoutActions.closeSlideout()),
})

const OrderSlideout = connect(mapStateToProps, mapDispatchToProps)(SlideoutComponent)

export default OrderSlideout
