import React from 'react'
import ViewReservationHeader from 'mgr/actualslideout/components/view/ViewReservationHeader'

const ViewOrderHeader = ({
  clientDisplayName,
  initialsDisplay,
  isVip,
  clientTagsDisplay,
  phoneNumberFormatted,
  photo,
  id,
  venueId,
  loyaltyIdDisplay,
  loyaltyRankDisplay,
  loyaltyTierDisplay,
}) => (
  <ViewReservationHeader
    name={clientDisplayName}
    initials={initialsDisplay}
    photoObj={photo}
    phone={phoneNumberFormatted}
    clientTags={clientTagsDisplay}
    isVip={isVip}
    clientId={id}
    venueId={venueId}
    loyaltyId={loyaltyIdDisplay}
    loyaltyRank={loyaltyRankDisplay}
    loyaltyTier={loyaltyTierDisplay}
  />
)

export default ViewOrderHeader
