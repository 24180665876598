import _ from 'lodash'
import React from 'react'
import styled from 'styled-components'
import DropdownArrowsPicker from 'mgr/lib/components/DropdownArrowsPicker'
import OrderCardBox from 'mgr/orderslideout/components/view/OrderCardBox'
import OrderContactCard from 'mgr/orderslideout/components/view/OrderContactCard'
import OrderCustomFieldsCards from 'mgr/orderslideout/components/view/OrderCustomFieldsCards'
import OrderDetailsCard from 'mgr/orderslideout/components/view/OrderDetailsCard'
import OrderFeedbackCard from 'mgr/orderslideout/components/view/OrderFeedbackCard'
import OrderPaymentCard from 'mgr/orderslideout/components/view/OrderPaymentCard'
import OrderSpendCard from 'mgr/orderslideout/components/view/OrderSpendCard'
import OrderSummaryCard from 'mgr/orderslideout/components/view/OrderSummaryCard'
import { FlexColumnContainer, FlexRowContainer } from 'mgr/orderslideout/components/view/ViewLayout'

const StatusMethodContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const CardDisplay = ({
  order,
  orderAvailableStatuses,
  venueGroupClient,
  feedback,
  currencySymbol,
  expandedRows,
  urlKey,
  actions,
  isModalSubmitting,
  notificationType,
  notificationTransactionId,
  notificationModalOpen,
  notificationEmail,
  refundingId,
  refundType,
  refundAmount,
  refundFull,
  refundDescription,
  refundSendNotification,
  deletingRequest,
  canCharge,
  canRefund,
  customFieldsConfig,
  formErrors,
  payoutProfileAccountId,
}) => (
  <FlexColumnContainer style={{ height: '100%' }}>
    <FlexRowContainer
      margin="0"
      style={{
        height: '64px',
        minHeight: '64px',
        marginBottom: '4px',
      }}
      alignItems="center"
    >
      <StatusMethodContainer>
        <DropdownArrowsPicker
          name="STATUS"
          value={order.status}
          choices={orderAvailableStatuses}
          disabled={order && order.sourceOrderSystem !== 'SEVENROOMS'}
          isLightTheme
          style={{
            width: 197,
            minWidth: 197,
            marginRight: 0,
            textTransform: 'capitalize',
          }}
          optionsContainerStyle={{
            width: 200,
            minWidth: 200,
          }}
          onChangeHandler={val => val !== order.status && actions.changeOrderStatus(order.id, val)}
          borderAreaStyle={{
            height: 66,
            paddingTop: 0,
            borderRadius: 0,
            marginLeft: 0,
            marginRight: 0,
            paddingBottom: 0,
          }}
        />
        <OrderCardBox label="TYPE" text={_.startCase(_.toLower(order.method))} />
      </StatusMethodContainer>
    </FlexRowContainer>
    <FlexColumnContainer style={{ overflowY: 'auto', overflowX: 'hidden', height: 'auto' }}>
      <OrderSummaryCard {...order} />
      <OrderSpendCard {...order} currencySymbol={currencySymbol} />
      <OrderDetailsCard {...order} />
      <OrderCustomFieldsCards {...{ order, customFieldsConfig }} />
      {order.feedbackId && <OrderFeedbackCard {...feedback} />}
      <OrderContactCard phone={order.phoneNumberFormatted} email={venueGroupClient.emailAddress} />
      <OrderPaymentCard
        order={order}
        transactions={order.transactions}
        selectedClient={order.venueGroupClient}
        expandedRows={expandedRows}
        actions={actions}
        currencySymbol={currencySymbol}
        urlKey={urlKey}
        isModalSubmitting={isModalSubmitting}
        notificationType={notificationType}
        notificationTransactionId={notificationTransactionId}
        notificationModalOpen={notificationModalOpen}
        notificationEmail={notificationEmail}
        refundingId={refundingId}
        refundType={refundType}
        refundAmount={refundAmount}
        refundFull={refundFull}
        refundDescription={refundDescription}
        refundSendNotification={refundSendNotification}
        deletingRequest={deletingRequest}
        canCharge={canCharge}
        canRefund={canRefund}
        formErrors={formErrors}
        payoutProfileAccountId={payoutProfileAccountId}
      />
    </FlexColumnContainer>
  </FlexColumnContainer>
)

export default CardDisplay
