import React from 'react'
import styled from 'styled-components'
import { SectionedCard, CardLabel } from 'mgr/orderslideout/components/view/ViewCommon'
import { StyledVmsIconXS, VmsIcons } from 'svr/common/VmsIcons'

const ContactFieldContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 16px;
`

const ContactFieldIcon = styled(StyledVmsIconXS)`
  color: #868e95;
  height: 13px;
  width: 16px;
  font-size: 13px;
  line-height: 14px;
`

const ContactFieldLabel = styled.div`
  height: 13px;
  width: 37px;
  color: #868e95;
  font-size: 11px;
  line-height: 13px;
  margin-left: 3px;
  width: 80%;
`

const ContactFieldValue = styled.div`
  height: 16px;
  font-size: 14px;
  line-height: 16px;
  margin-left: 19px;
`

const ContactField = ({ icon, label, value }) => (
  <ContactFieldContainer>
    <ContactFieldIcon>{icon}</ContactFieldIcon>
    <ContactFieldLabel>{label}</ContactFieldLabel>
    <ContactFieldValue>{value}</ContactFieldValue>
  </ContactFieldContainer>
)

const OrderContactCard = ({ phone, email }) => (
  <SectionedCard>
    <CardLabel>CONTACT</CardLabel>
    {phone && <ContactField key="phone" icon={VmsIcons.PhoneLine} value={phone} label="PHONE" />}
    {email && <ContactField key="email" icon={VmsIcons.EmailLine} value={email} label="EMAIL" />}
  </SectionedCard>
)

export default OrderContactCard
