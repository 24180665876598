import _ from 'lodash'
import React from 'react'
import styled from 'styled-components'
import { buildCustomFieldInputs, CustomFieldsSubCard } from 'mgr/actualslideout/components/view/DetailCard'
import { SectionedCard } from 'mgr/orderslideout/components/view/ViewCommon'

const OrderCustomFieldsDiv = styled.div`
  div {
    cursor: default !important;
  }
`

const OrderCustomFieldsCards = ({ order, customFieldsConfig }) => {
  // order.customUnindexed is automatically camel-cased, so we need to correspondingly update system_names
  const camelCaseCustomFieldsConfig = customFieldsConfig.map(cfc => ({
    ...cfc,
    system_name: cfc.system_name && _.camelCase(cfc.system_name),
  }))
  const customFieldInputs = buildCustomFieldInputs(camelCaseCustomFieldsConfig, {
    custom_group_0: order.customGroup0,
    custom_group_1: order.customGroup1,
    custom_group_2: order.customGroup2,
    custom_group_3: order.customGroup3,
    custom_group_4: order.customGroup4,
    custom_venue_0: order.customVenue0,
    custom_venue_1: order.customVenue1,
    custom_venue_2: order.customVenue2,
    custom_venue_3: order.customVenue3,
    custom_venue_4: order.customVenue4,
    custom_unindexed: order.customUnindexed,
  })
  return (
    <>
      {!_.isEmpty(customFieldInputs) && (
        <SectionedCard>
          <OrderCustomFieldsDiv>
            <CustomFieldsSubCard customFieldInputs={customFieldInputs} />
          </OrderCustomFieldsDiv>
        </SectionedCard>
      )}
    </>
  )
}

export default OrderCustomFieldsCards
