import _ from 'lodash'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import ActivityLogComponent from 'mgr/actualslideout/components/view/ActivityLogComponent'
import MessageDisplay from 'mgr/actualslideout/components/view/MessageDisplay'
import Tabs from 'mgr/actualslideout/components/view/Tabs'
import { FULL_HEIGHT } from 'mgr/actualslideout/components/view/ViewCommon'
import { ChatMessageBox } from 'mgr/actualslideout/components/ViewComponents'
import { tryPutExternalMessage } from '../../actions/ViewOrderActions'
import { FlexColumnContainer } from '../../components/view/ViewLayout'

const OrderSlideoutMessagingTabs = {
  ACTIVITY: 'ACTIVITY',
  MESSAGING: 'MESSAGING',
}

export function Messaging({ externalMessages, logEntries, actions, tabs, venueId, hasEmail }) {
  const [selectedTab, setSelectedTab] = useState(OrderSlideoutMessagingTabs.ACTIVITY)

  const [chatBoxHeight, setChatBoxHeight] = useState(60)
  const [message, setMessage] = useState('')
  const [showSendButton, setShowSendButton] = useState(false)

  let messageArea = null
  let messageEntryBox = null
  switch (selectedTab) {
    case OrderSlideoutMessagingTabs.MESSAGING:
      messageArea = <MessageDisplay messages={externalMessages} venueId={venueId} messageEntryBoxHeight={chatBoxHeight} isOrderSlideout />
      messageEntryBox = (
        <ChatMessageBox
          hasEmail={hasEmail}
          placeholder="Send an email message..."
          sendMessage={actions.sendExternalMessage}
          removeAttachmentArea
          height={chatBoxHeight}
          setHeight={setChatBoxHeight}
          message={message}
          setMessage={setMessage}
          showSendButton={showSendButton}
          setShowSendButton={setShowSendButton}
        />
      )
      break
    case OrderSlideoutMessagingTabs.ACTIVITY:
      messageArea = <ActivityLogComponent logEntries={logEntries} />
      break
    default:
      break
  }

  return (
    <FlexColumnContainer style={FULL_HEIGHT}>
      <Tabs tabs={tabs} activeTab={selectedTab} onTabChange={setSelectedTab} />
      {messageArea}
      {messageEntryBox}
    </FlexColumnContainer>
  )
}

const mapStateToProps = state => {
  const tabs = [
    { action: OrderSlideoutMessagingTabs.ACTIVITY, text: 'ALL ACTIVITY' },
    {
      action: OrderSlideoutMessagingTabs.MESSAGING,
      text: `MESSAGING (${_.get(state, ['viewOrderState', 'externalMessages', 'length'], 0)})`,
    },
  ]
  return {
    externalMessages: state.viewOrderState.externalMessages,
    logEntries: state.viewOrderState.activityLog,
    tabs,
    venueId: state.viewOrderState.order.venueId,
    hasEmail: !!state.viewOrderState.venueGroupClient.emailAddress,
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      sendExternalMessage: tryPutExternalMessage,
    },
    dispatch
  ),
})

export default connect(mapStateToProps, mapDispatchToProps)(Messaging)
