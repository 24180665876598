import _ from 'lodash'
import moment from 'moment-timezone'
import React from 'react'
import styled from 'styled-components'
import { SectionedCard } from 'mgr/orderslideout/components/view/ViewCommon'
import { FlexRowContainer } from 'mgr/orderslideout/components/view/ViewLayout'
import { StyledVmsIconXS, VmsIcons } from 'svr/common/VmsIcons'
import { FULFILLMENT_METHOD } from 'svr/constants'
import { Icon } from '@sevenrooms/core/ui-kit/icons'

const CardText = styled.span`
  color: ${props => props.theme.navigationDark};
`
const MajorCardText = styled(CardText)`
  font-size: 15px;
  font-weight: 500;
  line-height: 15px;
  ${props => props.secondaryColor && 'color: #6A6A6A;'};
`

const SpacedIcon = styled(StyledVmsIconXS)`
  margin-right: 16px;
  line-height: 15px;
  text-align: center;
  color: ${props => props.theme.navigationDark};
`

const SummaryIconRow = styled(FlexRowContainer)`
  padding: 8px 0px;
  color: ${props => props.theme.navigationDark};
`

const IconAndTextMajor = ({ icon, text, secondaryColor }) => (
  <SummaryIconRow>
    <SpacedIcon>{icon}</SpacedIcon>
    <MajorCardText secondaryColor={secondaryColor}>{text}</MajorCardText>
  </SummaryIconRow>
)

const orderTimestampFormatOrNil = (ts, tz) => ts && moment(ts).tz(tz).format('ddd MMM D @ h:mm A')
const orderTimestampFormat = (ts, tz) => orderTimestampFormatOrNil(ts, tz) || '-'
const orderTimeFormat = (ts, tz) => moment(ts).tz(tz).format('LT')
const desiredRangeFormat = (targetReadyDatetimeLocal, desiredBufferMinutes, tz) => {
  if (_.isNil(targetReadyDatetimeLocal)) {
    return ''
  }
  const endDatetimeLocal = moment(targetReadyDatetimeLocal).tz(tz).clone().add({ minutes: desiredBufferMinutes })
  const startFormatted = orderTimeFormat(targetReadyDatetimeLocal, tz)
  const endFormatted = orderTimeFormat(endDatetimeLocal, tz)
  return ` (${startFormatted} - ${endFormatted})`
}

const OrderSummaryCard = ({
  venueInternalDisplayName,
  method,
  locationId,
  timezone,
  roomNumber,
  posTableId,
  posTableNumber,
  openedDatetimeLocal,
  closedDatetimeLocal,
  desiredDatetimeLocal,
  targetReadyDatetimeLocal,
  guestReceivingDatetimeLocal,
  desiredBufferMinutes,
  deliveryStatusDisplay,
  deliveryDriverName,
  deliveryDriverPhone,
  deliveryPickupEtaLocal,
  deliveryDropoffEtaLocal,
}) => {
  const tz = timezone.zone
  const openedTimestampFormatted = orderTimestampFormat(openedDatetimeLocal, tz)
  const targetReadyTimestampFormatted = _.isNil(desiredDatetimeLocal)
    ? 'ASAP'
    : orderTimestampFormat(targetReadyDatetimeLocal || guestReceivingDatetimeLocal, tz)
  const desiredRangeFormatted = desiredRangeFormat(guestReceivingDatetimeLocal, desiredBufferMinutes, tz)
  const closedTimestampFormatted = orderTimestampFormat(closedDatetimeLocal, tz)
  const tableRowText = method === FULFILLMENT_METHOD.ON_PREMISE ? posTableNumber || posTableId || locationId : undefined
  const driverRows = [
    ['Driver Status', deliveryStatusDisplay],
    ['Driver', deliveryDriverName],
    ['Driver Phone', deliveryDriverPhone],
    ['Pickup ETA', orderTimestampFormatOrNil(deliveryPickupEtaLocal, tz)],
    ['Dropoff ETA', orderTimestampFormatOrNil(deliveryDropoffEtaLocal, tz)],
  ].map(
    ([driverStatusName, driverStatusValue], i) =>
      !!driverStatusValue && <IconAndTextMajor icon={i === 0 ? VmsIcons.Walkin : ''} text={`${driverStatusName}: ${driverStatusValue}`} />
  )
  return (
    <SectionedCard>
      <IconAndTextMajor icon={VmsIcons.Location} text={venueInternalDisplayName} />
      {!!tableRowText && <IconAndTextMajor icon={VmsIcons.TableSquare} text={tableRowText} />}
      <IconAndTextMajor icon={VmsIcons.Receipt} text={openedTimestampFormatted} />
      {roomNumber && <IconAndTextMajor icon={<Icon name="VMSWeb-door-open" />} text={`Room ${roomNumber}`} />}
      <IconAndTextMajor
        icon={VmsIcons.Target}
        text={targetReadyTimestampFormatted + desiredRangeFormatted}
        secondaryColor={!!closedDatetimeLocal}
      />
      <IconAndTextMajor icon={VmsIcons.Checked} text={closedTimestampFormatted} />
      {driverRows}
    </SectionedCard>
  )
}

export default OrderSummaryCard
