import _ from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as ViewOrderActions from 'mgr/orderslideout/actions/ViewOrderActions'
import { transformOrderStatusesToDropdown } from 'mgr/orderslideout/utils/orderStatusDropdownUtils'
import { Col, Container, Row } from 'svr/lib/styled-bootstrap-grid'
import ViewOrderHeader from '../components/view/ViewOrderHeader'
import OrderSlideout from './OrderSlideout'
import CardDisplay from './view/CardDisplay'
import Messaging from './view/Messaging'

const ViewOrder = ({
  isLoading,
  order,
  orderAvailableStatuses,
  venueGroupClient,
  feedback,
  currencySymbol,
  showMessagingTab,
  phoneNumberFormatted,
  expandedRows,
  urlKey,
  actions,
  isModalSubmitting,
  notificationType,
  notificationTransactionId,
  notificationModalOpen,
  notificationEmail,
  refundingId,
  refundType,
  refundAmount,
  refundFull,
  refundDescription,
  refundSendNotification,
  deletingRequest,
  canCharge,
  canRefund,
  customFieldsConfig,
  formErrors,
  payoutProfileAccountId,
}) => {
  if (isLoading) {
    return <OrderSlideout title="Loading..." />
  }
  const { orderNumber } = order
  return (
    <OrderSlideout title={`ORDER ${orderNumber}`}>
      <Container
        style={{
          paddingRight: 0,
          paddingLeft: 0,
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'hidden',
          overflowX: 'hidden',
        }}
      >
        <ViewOrderHeader
          {...venueGroupClient}
          venueId={order.venueId}
          clientDisplayName={order.clientDisplayName}
          phoneNumberFormatted={phoneNumberFormatted}
        />
        <Row style={{ flex: 1, overflow: 'hidden' }}>
          <Col lg={6} style={{ height: '100%', paddingRight: 0 }}>
            <CardDisplay
              order={order}
              orderAvailableStatuses={orderAvailableStatuses}
              venueGroupClient={venueGroupClient}
              feedback={feedback}
              currencySymbol={currencySymbol}
              expandedRows={expandedRows}
              urlKey={urlKey}
              actions={actions}
              isModalSubmitting={isModalSubmitting}
              notificationType={notificationType}
              notificationTransactionId={notificationTransactionId}
              notificationModalOpen={notificationModalOpen}
              notificationEmail={notificationEmail}
              refundingId={refundingId}
              refundType={refundType}
              refundAmount={refundAmount}
              refundFull={refundFull}
              refundDescription={refundDescription}
              refundSendNotification={refundSendNotification}
              deletingRequest={deletingRequest}
              canCharge={canCharge}
              canRefund={canRefund}
              customFieldsConfig={customFieldsConfig}
              formErrors={formErrors}
              payoutProfileAccountId={payoutProfileAccountId}
            />
          </Col>
          <Col
            lg={6}
            style={{
              height: '100%',
              paddingLeft: 0,
              borderLeft: '1px solid #d1d2d3',
            }}
          >
            {showMessagingTab && <Messaging />}
          </Col>
        </Row>
      </Container>
    </OrderSlideout>
  )
}

const mapStateToProps = state => ({
  isLoading: state.slideoutState.isLoading,
  order: state.viewOrderState.order,
  orderAvailableStatuses: transformOrderStatusesToDropdown(
    _.get(state, 'viewOrderState.order.status'),
    _.get(state, 'viewOrderState.order.method')
  ),
  venueGroupClient: state.viewOrderState.venueGroupClient,
  feedback: state.viewOrderState.feedback,
  expandedRows: state.viewOrderState.expandedRows,
  isModalSubmitting: state.viewOrderState.isModalSubmitting,
  notificationType: state.viewOrderState.notificationType,
  notificationTransactionId: state.viewOrderState.notificationTransactionId,
  notificationModalOpen: state.viewOrderState.notificationModalOpen,
  notificationEmail: state.viewOrderState.notificationEmail,
  refundingId: state.viewOrderState.refundingId,
  refundType: state.viewOrderState.refundType,
  refundAmount: state.viewOrderState.refundAmount,
  refundFull: state.viewOrderState.refundFull,
  refundDescription: state.viewOrderState.refundDescription,
  refundSendNotification: state.viewOrderState.refundSendNotification,
  deletingRequest: state.viewOrderState.deletingRequest,
  formErrors: state.viewOrderState.formErrors,
  currencySymbol: _.get(state, 'viewOrderState.viewVenue.currencySymbol', state.appState.venue.currencySymbol),
  canCharge: _.get(state, 'viewOrderState.viewVenue.permissions.canCharge', false),
  canRefund: _.get(state, 'viewOrderState.viewVenue.permissions.canRefund', false),
  customFieldsConfig: _.get(state, 'viewOrderState.viewVenue.customFieldsConfig.order', []),
  urlKey: state.appState.venue.urlKey,
  phoneNumberFormatted:
    _.get(state, ['viewOrderState', 'venueGroupClient', 'phoneNumberFormatted'], '') ||
    _.get(state, ['viewOrderState', 'order', 'phoneNumberFormatted'], ''),
  showMessagingTab: !state.viewOrderState.isLoadingActivityLog && !state.viewOrderState.isLoadingMessages,
  payoutProfileAccountId: state.appState.venue.accountId,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...ViewOrderActions }, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(ViewOrder)
