import _ from 'lodash'
import React from 'react'
import styled from 'styled-components'
import { SectionedCard, CardLabel } from 'mgr/orderslideout/components/view/ViewCommon'

const TotalSpendLine = styled.div`
  height: 24px;
  width: 336px;
  font-size: 21px;
  line-height: 24px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
`

const ShowDetailsButton = styled.div`
  height: 14px;
  width: 336px;
  color: #347baf;
  font-size: 12px;
  font-weight: bold;
  line-height: 14px;
  cursor: pointer;
  margin-bottom: 9px;
`

const OrderSpendText = styled.span``

const AdditionalDetailsContainer = styled.div``

const SpendSummaryRectangle = styled.div`
  height: 172px;
  width: 340px;
  border-radius: 4px;
  background-color: #f6f6f6;
  padding: 14px 15px 12px 17px;
`

const SpendSummaryLine = styled.div`
  height: 15px;
  color: #9a9b9c;
  font-size: 13px;
  line-height: 15px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 14px;
`

const CardDivider = styled.div`
  box-sizing: border-box;
  height: 1px;
  border: 1px solid ${props => props.theme.lighterSilver};
  margin-bottom: 12px;
`

const ItemBreakdownContainer = styled.div`
  margin-top: 40px;
  color: #9a9b9c;
  font-size: 13px;
  line-height: 15px;
  text-align: left;
`

const OrderNumberLine = styled.div`
  margin-bottom: 15px;
  display: flex;
`

const OrderNumberText = styled.div`
  width: 100px;
`

const ItemBreakdownList = styled.div``

const ItemBreakdownHeader = styled.div`
  border-top: 1px solid #c9c9c9;
  border-bottom: 1px solid #c9c9c9;
  padding: 8px 15px 6px 10px;
  height: 14px;
  font-size: 11px;
  line-height: 14px;
  display: flex;
  justify-content: space-between;
  height: 28px;
`

const ItemsOrderedText = styled.div`
  width: 255px;
`

const ItemBreakdownLine = styled.div`
  display: flex;
  margin: 7px 15px 0 10px;
`

const ItemBreakdownQuantity = styled.div`
  width: 28px;
`

const ItemBreakdownName = styled.div`
  width: 187px;
  overflow: hidden;
  text-overflow: ellipsis;
`

const ItemBreakdownPrice = styled.div`
  width: 100px;
  text-align: right;
`

const ShowLessButton = styled.div`
  height: 14px;
  color: #347baf;
  font-size: 12px;
  font-weight: bold;
  line-height: 14px;
  margin: 15px 10px 12px 10px;
  cursor: pointer;
`

const ITEM_STATUS_VOID = 'VOID'

class OrderSpendCard extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = { isOpen: false }
  }

  toggleDetails = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  getCurrencyString = value => `${this.props.currencySymbol}${value.toFixed(2)}`

  render() {
    const {
      orderNumber,
      posTicket,
      posTableName,
      deliveryFee,
      discount,
      tax,
      tip,
      subtotal,
      total,
      tipPercentageFormatted,
      taxPercentageFormatted,
      serviceCharges,
    } = this.props
    const filteredPosTicketItems = _.filter(posTicket.items, item => item.status !== ITEM_STATUS_VOID)
    const numItemsOrdered = _.keys(filteredPosTicketItems || []).length
    const showItemBreakdown = !!numItemsOrdered
    return (
      <SectionedCard>
        <CardLabel>SPEND</CardLabel>
        <TotalSpendLine>
          <OrderSpendText>Total Net Spend</OrderSpendText>
          <OrderSpendText>{this.getCurrencyString(total)}</OrderSpendText>
        </TotalSpendLine>
        {!this.state.isOpen && <ShowDetailsButton onClick={this.toggleDetails}>SHOW DETAILS</ShowDetailsButton>}
        {this.state.isOpen && (
          <AdditionalDetailsContainer>
            <SpendSummaryRectangle>
              {serviceCharges.map(serviceCharge => {
                const amountDollars = serviceCharge.isPassThroughThirdParty ? deliveryFee : serviceCharge.amount / 100
                return (
                  amountDollars && (
                    <SpendSummaryLine>
                      <OrderSpendText>{serviceCharge.name}</OrderSpendText>
                      <OrderSpendText>{this.getCurrencyString(amountDollars)}</OrderSpendText>
                    </SpendSummaryLine>
                  )
                )
              })}
              <SpendSummaryLine>
                <OrderSpendText>Subtotal</OrderSpendText>
                <OrderSpendText>{this.getCurrencyString(subtotal)}</OrderSpendText>
              </SpendSummaryLine>
              {!!discount && (
                <SpendSummaryLine>
                  <OrderSpendText>Discount</OrderSpendText>
                  <OrderSpendText>({this.getCurrencyString(discount)})</OrderSpendText>
                </SpendSummaryLine>
              )}
              <SpendSummaryLine>
                <OrderSpendText>{`Tax (${taxPercentageFormatted})`}</OrderSpendText>
                <OrderSpendText>{this.getCurrencyString(tax)}</OrderSpendText>
              </SpendSummaryLine>
              <SpendSummaryLine>
                <OrderSpendText>{`Tip (${tipPercentageFormatted})`}</OrderSpendText>
                <OrderSpendText>{this.getCurrencyString(tip)}</OrderSpendText>
              </SpendSummaryLine>
              <CardDivider />
              <SpendSummaryLine>
                <OrderSpendText>Total Gross</OrderSpendText>
                <OrderSpendText>{this.getCurrencyString(total)}</OrderSpendText>
              </SpendSummaryLine>
            </SpendSummaryRectangle>
            <ItemBreakdownContainer>
              <OrderNumberLine>
                <OrderNumberText>Order No.</OrderNumberText>
                <OrderNumberText>{orderNumber}</OrderNumberText>
              </OrderNumberLine>
              {posTableName && (
                <OrderNumberLine>
                  <OrderNumberText>POS Table</OrderNumberText>
                  <OrderNumberText>{posTableName}</OrderNumberText>
                </OrderNumberLine>
              )}
              {showItemBreakdown && (
                <ItemBreakdownList>
                  <ItemBreakdownHeader>
                    <ItemsOrderedText>{`ITEMS ORDERED (${numItemsOrdered})`}</ItemsOrderedText>
                    <ItemBreakdownPrice>Price</ItemBreakdownPrice>
                  </ItemBreakdownHeader>
                  {_.map(filteredPosTicketItems, (item, idx) => (
                    <ItemBreakdownLine key={idx}>
                      <ItemBreakdownQuantity>{item.quantity}</ItemBreakdownQuantity>
                      <ItemBreakdownName>{item.name}</ItemBreakdownName>
                      <ItemBreakdownPrice>{this.getCurrencyString(item.price)}</ItemBreakdownPrice>
                    </ItemBreakdownLine>
                  ))}
                </ItemBreakdownList>
              )}
              <ShowLessButton onClick={this.toggleDetails}>SHOW LESS</ShowLessButton>
            </ItemBreakdownContainer>
          </AdditionalDetailsContainer>
        )}
      </SectionedCard>
    )
  }
}

export default OrderSpendCard
