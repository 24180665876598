import { combineReducers } from 'redux'
import { appStateReducer } from 'mgr/lib/reducers/UtilReducers'
import slideoutReducer from './SlideoutReducer'
import ViewOrderReducer from './ViewOrderReducer'

const rootReducer = combineReducers({
  appState: appStateReducer,
  slideoutState: slideoutReducer,
  viewOrderState: ViewOrderReducer,
})

export default rootReducer
