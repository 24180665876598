import React from 'react'
import styled from 'styled-components'
import { SectionedCard, CardLabel } from 'mgr/orderslideout/components/view/ViewCommon'
import { SingleStarRow } from 'mgr/pages/single-venue/marketing/components/guest-satisfaction/StarsData'
import { StarSlide } from 'mgr/pages/single-venue/marketing/components/Review'

const OverallRatingContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const OverallRatingLabel = styled.div`
  height: 24px;
  font-size: 21px;
  line-height: 24px;
`

const NotesContainer = styled.div`
  margin-top: 15px;
  font-size: 13px;
  line-height: 15px;
`

const DetailsContainer = styled.div`
  margin-top: 19px;
  border-top: 1px solid #e8eaed;
  border-bottom: 1px solid #e8eaed;
  padding-top: 17px;
  padding-bottom: 18px;
  height: 147px;
  color: #868e95;
  font-size: 14px;
  line-height: 16px;
`

const DetailsRow = styled.div`
  display: flex;
  justify-content: space-between;
  color: #868e95;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 15px;
`

const DetailSpan = styled.span``

const ShowDetailsButton = styled.div`
  height: 14px;
  width: 336px;
  color: #347baf;
  font-size: 12px;
  font-weight: bold;
  line-height: 14px;
  cursor: pointer;
  margin-bottom: 9px;
  margin-top: 12px;
`

const HideDetailsButton = styled.div`
  height: 19px;
  width: 103px;
  color: #347baf;
  font-size: 16px;
  font-weight: bold;
  line-height: 19px;
  margin-top: 14px;
  margin-bottom: 7px;
  cursor: pointer;
`

class OrderFeedbackCard extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = { isOpen: false }
  }

  toggleDetails = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  render() {
    const { overallRating, notes, wasOrderAccurateDisplay, arrivedOnTimeDisplay, recommendToFriendDisplay, foodRating } = this.props
    return (
      <SectionedCard>
        <CardLabel>FEEDBACK</CardLabel>
        <OverallRatingContainer>
          <OverallRatingLabel>Overall</OverallRatingLabel>
          <StarSlide>
            <SingleStarRow numStars={5} starsChecked={overallRating} css="margin-bottom: 0; margin-right: -6px;" />
          </StarSlide>
        </OverallRatingContainer>
        <NotesContainer>{notes}</NotesContainer>
        {this.state.isOpen ? (
          <DetailsContainer>
            <DetailsRow>
              <DetailSpan>Food</DetailSpan>
              <StarSlide>
                <SingleStarRow numStars={5} starsChecked={foodRating} css="margin-bottom: 0; margin-right: -6px;" />
              </StarSlide>
            </DetailsRow>
            <DetailsRow>
              <DetailSpan>On time</DetailSpan>
              <DetailSpan>{arrivedOnTimeDisplay}</DetailSpan>
            </DetailsRow>
            <DetailsRow>
              <DetailSpan>Accurate</DetailSpan>
              <DetailSpan>{wasOrderAccurateDisplay}</DetailSpan>
            </DetailsRow>
            <DetailsRow>
              <DetailSpan>Recommended</DetailSpan>
              <DetailSpan>{recommendToFriendDisplay}</DetailSpan>
            </DetailsRow>
          </DetailsContainer>
        ) : (
          <ShowDetailsButton onClick={this.toggleDetails}>SHOW DETAILS</ShowDetailsButton>
        )}
        {this.state.isOpen && <HideDetailsButton onClick={this.toggleDetails}>HIDE DETAILS</HideDetailsButton>}
      </SectionedCard>
    )
  }
}

export default OrderFeedbackCard
