import * as GlobalActionTypes from 'mgr/lib/actions/GlobalActionTypes'
import { NotificationLevel } from 'mgr/lib/utils/Constants'
import * as OrderActionTypes from './ActionTypes'

export const initialize = globalInit => ({
  type: GlobalActionTypes.INITIALIZE,
  globalInit,
})

export const closeSlideout = () => ({ type: OrderActionTypes.CLOSE_SLIDEOUT })

export const showNotificationBanner = notificationBannerState => ({
  type: OrderActionTypes.SHOW_NOTIFICATION_BANNER,
  notificationBannerState,
})

export const showNotificationError = message =>
  showNotificationBanner({
    message,
    level: NotificationLevel.ERROR,
    visible: true,
  })

export const closeNotificationBanner = () => ({
  type: OrderActionTypes.CLOSE_NOTIFICATION_BANNER,
})
export const clickNotificationBannerAction = () => ({
  type: OrderActionTypes.CLICK_NOTIFICATION_BANNER_ACTION,
})
