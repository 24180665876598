import * as ActionTypes from 'mgr/orderslideout/actions/ActionTypes'

const initialSlideoutState = {
  isSlideoutOpen: false,
  isLoading: true,
  notificationBannerState: {
    message: '',
    level: 'INFO', // INFO, WARN, ERROR, SUCCESS
    visible: false,
    actionText: null,
    actionType: null,
  },
}

const slideoutReducer = (state = initialSlideoutState, action) => {
  switch (action.type) {
    case ActionTypes.VIEW_ORDER: {
      return {
        ...state,
        isSlideoutOpen: true,
        isLoading: true,
      }
    }
    case ActionTypes.LOAD_ORDER_START:
      return { ...state, isLoading: true }
    case ActionTypes.LOAD_ORDER_SUCCESS:
      return { ...state, isLoading: false }
    case ActionTypes.LOAD_ORDER_FAIL:
    case ActionTypes.CLOSE_SLIDEOUT:
      return {
        ...initialSlideoutState,
        notificationBannerState: {
          ...state.notificationBannerState,
        },
      }
    case ActionTypes.SHOW_NOTIFICATION_BANNER:
      return {
        ...state,
        notificationBannerState: {
          ...action.notificationBannerState,
          visible: true,
        },
      }
    case ActionTypes.CLOSE_NOTIFICATION_BANNER:
    case ActionTypes.CLICK_NOTIFICATION_BANNER_ACTION:
      return {
        ...state,
        notificationBannerState: {
          ...state.notificationBannerState,
          visible: false,
        },
      }
    default:
      return state
  }
}

export default slideoutReducer
