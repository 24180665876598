import React from 'react'
import styled from 'styled-components'
import { makeOptionalAttr } from 'mgr/layout/StyledComponentUtils'

export const FlexBoxContainer = styled.div`
  display: flex;
  list-style: none;
  flex-wrap: nowrap;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  ${props => makeOptionalAttr('margin', props.margin)} ${props => makeOptionalAttr('padding', props.padding)};
`

export const FlexRowContainer = styled(FlexBoxContainer)`
  flex-direction: row;
  align-items: ${props => props.alignItems || 'center'};
  justify-content: ${props => props.justifyContent || 'flex-start'};
  ${props => makeOptionalAttr('flex-wrap', props.flexWrap)};
`

export const FlexColumnContainer = styled(FlexBoxContainer)`
  flex-direction: column;
  ${props => makeOptionalAttr('align-items', props.alignItems)} ${props => makeOptionalAttr('justify-content', props.justifyContent)};
`
