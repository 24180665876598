import React from 'react'
import MessageBox from 'svr/component-lib/Manager/MessageBox'

const RefundConfirmationModal = ({ okAction, cancelAction, transactionToRefund }) => (
  <MessageBox
    handleCloseClick={cancelAction}
    handleActionClick={() => okAction(transactionToRefund.id, transactionToRefund.amount_remaining_decimal, '', true)}
    dialogType={MessageBox.DialogType.WARNING}
    header="Refund Order?"
    explain="Refund Order?"
    details={[]}
    explanation="Would you like to fully refund this order?"
    actionButtonText="Yes"
    cancelLinkText="No"
  />
)

export default RefundConfirmationModal
