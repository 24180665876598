import _ from 'lodash'
import React from 'react'
import styled from 'styled-components'
import NotificationForm from 'mgr/actualslideout/components/payment/NotificationForm'
import Transaction from 'mgr/actualslideout/components/payment/Transaction'
import { SectionedCard, CardLabel } from 'mgr/orderslideout/components/view/ViewCommon'

const CardBodyText = styled.div`
  width: 334px;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 17px;
`

const TransactionList = ({
  order,
  transactions,
  refundData,
  selectedClient,
  expandedRows,
  currencySymbol,
  urlKey,
  deletingRequest,
  canCharge,
  canRefund,
  actions,
  formErrors,
  validateFieldMap,
  onSubmitRefundHandler,
  onSubmitNotificationHandler,
  payoutProfileAccountId,
}) => (
  <div>
    {transactions.map(transaction => {
      if (!transaction || !transaction.transaction_type) {
        return null
      }
      // Suppresses key warning
      if (!transaction.id) {
        // eslint-disable-next-line no-param-reassign
        transaction.id = new Date().getTime()
      }
      return (
        <Transaction
          key={transaction.id}
          transaction={transaction}
          refundData={refundData}
          selectedClient={selectedClient}
          expandedRows={expandedRows}
          currencySymbol={currencySymbol}
          formErrors={formErrors}
          validateFieldMap={validateFieldMap}
          urlKey={urlKey}
          deletingRequest={deletingRequest}
          actions={actions}
          onSubmitRefundHandler={onSubmitRefundHandler}
          onSubmitNotificationHandler={onSubmitNotificationHandler}
          canCharge={canCharge}
          canRefund={canRefund}
          order={order}
          payoutProfileAccountId={payoutProfileAccountId}
        />
      )
    })}
  </div>
)

const OrderPaymentCard = ({
  order,
  transactions,
  selectedClient,
  expandedRows,
  currencySymbol,
  urlKey,
  actions,
  isModalSubmitting,
  notificationType,
  notificationTransactionId,
  notificationModalOpen,
  notificationEmail,
  refundingId,
  refundType,
  refundAmount,
  refundFull,
  refundDescription,
  refundSendNotification,
  deletingRequest,
  canCharge,
  canRefund,
  formErrors,
  payoutProfileAccountId,
}) => {
  const validateFieldMap = {}

  const findInvalidFieldsInSection = validateFieldMap =>
    _.reduce(
      validateFieldMap,
      (invalidFields, input, field) => {
        const invalidText = !_.isNil(input) && ((input.props && input.props.disabled) || input.isValid())
        return _.assign(invalidFields, invalidText !== true && invalidText !== false && { [field]: invalidText })
      },
      {}
    )

  const onSubmitRefundHandler = () => {
    const errors = findInvalidFieldsInSection(validateFieldMap)
    actions.chargeFormValidated(errors)
    if (_.isEmpty(errors)) {
      actions.submitRefund()
    }
  }

  const onSubmitNotificationHandler = isRefund => {
    const errors = findInvalidFieldsInSection(validateFieldMap)
    actions.chargeFormValidated(errors)
    if (_.isEmpty(errors)) {
      actions.sendNotification(isRefund)
    }
  }

  const refundData = {
    refundingId,
    refundType,
    refundAmount,
    refundDescription,
    refundSendNotification,
    notificationEmail,
    isModalSubmitting,
  }

  const transactionValues = _.values(transactions)
  const transactionContents = !_.isEmpty(_.values(transactionValues)) ? (
    <TransactionList
      order={order}
      payoutProfileAccountId={payoutProfileAccountId}
      transactions={transactionValues}
      refundData={refundData}
      selectedClient={selectedClient}
      formErrors={formErrors}
      validateFieldMap={validateFieldMap}
      expandedRows={expandedRows}
      currencySymbol={currencySymbol}
      urlKey={urlKey}
      deletingRequest={deletingRequest}
      canCharge={canCharge}
      canRefund={canRefund}
      actions={actions}
      onSubmitRefundHandler={onSubmitRefundHandler}
      onSubmitNotificationHandler={onSubmitNotificationHandler}
    />
  ) : null

  return (
    <SectionedCard>
      <CardLabel>TRANSACTIONS</CardLabel>
      {transactionContents}
      {notificationModalOpen ? (
        <NotificationForm
          notificationEmail={notificationEmail}
          notificationType={notificationType}
          selectedClient={selectedClient}
          isModalSubmitting={isModalSubmitting}
          actions={actions}
          formErrors={formErrors}
          validateFieldMap={validateFieldMap}
          onSubmitNotificationHandler={onSubmitNotificationHandler}
        />
      ) : null}
    </SectionedCard>
  )
}

export default OrderPaymentCard
