import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { ThemeProvider } from 'styled-components'
import GlobalNotificationBanner from 'mgr/layout/GlobalNotificationBanner'
import Theme from 'mgr/layout/Theme'
import * as SlideoutActions from 'mgr/orderslideout/actions/SlideoutActions'
import * as ViewOrderActions from 'mgr/orderslideout/actions/ViewOrderActions'
import RefundConfirmationModal from '../components/view/RefundConfirmationModal'
import ViewOrder from './ViewOrder'

class App extends PureComponent {
  constructor(props) {
    super(props)
  }

  componentWillMount() {
    const { actions, globalInit } = this.props
    actions.initialize(globalInit)
  }

  render() {
    const { notificationBannerState, actions, showRefundConfirmationModal, transactionToRefund } = this.props
    return (
      <ThemeProvider theme={Theme}>
        <div>
          {showRefundConfirmationModal && (
            <RefundConfirmationModal
              okAction={actions.submitCustomRefund}
              cancelAction={actions.closeRefundConfirmationModal}
              transactionToRefund={transactionToRefund}
            />
          )}
          <ViewOrder />
          <GlobalNotificationBanner
            {...notificationBannerState}
            handleClose={actions.closeNotificationBanner}
            handleAction={actions.clickNotificationBannerAction}
          />
        </div>
      </ThemeProvider>
    )
  }
}

const mapStateToProps = state => ({
  notificationBannerState: state.slideoutState.notificationBannerState,
  showRefundConfirmationModal: state.viewOrderState.showRefundConfirmationModal,
  transactionToRefund: state.viewOrderState.orderStatusVoidTransactionToRefund,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      initialize: SlideoutActions.initialize,
      closeNotificationBanner: SlideoutActions.closeNotificationBanner,
      clickNotificationBannerAction: SlideoutActions.clickNotificationBannerAction,
      closeRefundConfirmationModal: ViewOrderActions.closeRefundConfirmationModal,
      submitCustomRefund: ViewOrderActions.submitCustomRefund,
    },
    dispatch
  ),
})

App = connect(mapStateToProps, mapDispatchToProps)(App)

export default App
