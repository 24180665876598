export const VIEW_ORDER = 'VIEW_ORDER'

export const LOAD_ORDER_START = 'LOAD_ORDER_START'
export const LOAD_ORDER_SUCCESS = 'LOAD_ORDER_SUCCESS'
export const LOAD_ORDER_FAIL = 'LOAD_ORDER_FAIL'

export const CLOSE_SLIDEOUT = 'CLOSE_SLIDEOUT'

export const SHOW_NOTIFICATION_BANNER = 'SHOW_NOTIFICATION_BANNER'
export const CLICK_NOTIFICATION_BANNER_ACTION = 'CLICK_NOTIFICATION_BANNER_ACTION'
export const CLOSE_NOTIFICATION_BANNER = 'CLOSE_NOTIFICATION_BANNER'

export const LOAD_ACTIVITY_LOG_START = 'LOAD_ACTIVITY_LOG_START'
export const LOAD_ACTIVITY_LOG_SUCCESS = 'LOAD_ACTIVITY_LOG_SUCCESS'
export const LOAD_ACTIVITY_LOG_FAIL = 'LOAD_ACTIVITY_LOG_FAIL'

export const LOAD_MESSAGES_START = 'LOAD_MESSAGES_START'
export const LOAD_MESSAGES_SUCCESS = 'LOAD_MESSAGES_SUCCESS'
export const LOAD_MESSAGES_FAIL = 'LOAD_MESSAGES_FAIL'

export const SEND_MESSAGE_START = 'SEND_MESSAGE_START'
export const SEND_MESSAGE_FAIL = 'SEND_MESSAGE_FAIL'
export const SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS'

export const PAYMENT_TOGGLE_DETAIL = 'PAYMENT_TOGGLE_DETAIL'
export const PAYMENT_NOTIFICATION_START = 'PAYMENT_NOTIFICATION_START'
export const PAYMENT_SET_REFUND_ID = 'PAYMENT_SET_REFUND_ID'
export const PAYMENT_TOGGLE_MODAL_NOTIFICATION = 'PAYMENT_TOGGLE_MODAL_NOTIFICATION'
export const PAYMENT_REFUND_START = 'PAYMENT_REFUND_START'
export const PAYMENT_REFUND_FAIL = 'PAYMENT_REFUND_FAIL'
export const PAYMENT_REFUND_SUCCESS = 'PAYMENT_REFUND_SUCCESS'
export const PAYMENT_NOTIFICATION_SUCCESS = 'PAYMENT_NOTIFICATION_SUCCESS'
export const PAYMENT_NOTIFICATION_FAIL = 'PAYMENT_NOTIFICATION_FAIL'
export const REFUND_TOGGLE_NOTIFICATION = 'REFUND_TOGGLE_NOTIFICATION'
export const REFUND_CHANGE_CHARGE_AMOUNT = 'REFUND_CHANGE_CHARGE_AMOUNT'
export const REFUND_CHANGE_TYPE = 'REFUND_CHANGE_TYPE'
export const REFUND_CHANGE_DESCRIPTION = 'REFUND_CHANGE_DESCRIPTION'
export const PAYMENT_CHANGE_CHARGE_NOTIFICATION_EMAIL = 'PAYMENT_CHANGE_CHARGE_NOTIFICATION_EMAIL'
export const CHARGE_FORM_VALIDATED = 'CHARGE_FORM_VALIDATED'
export const PUSH_PAYMENT_TRANSACTION = 'PUSH_PAYMENT_TRANSACTION'

export const ORDER_STATUS_CHANGE = 'ORDER_STATUS_CHANGE'

export const OPEN_REFUND_CONFIRMATION_MODAL = 'OPEN_REFUND_CONFIRMATION_MODAL'
export const CLOSE_REFUND_CONFIRMATION_MODAL = 'CLOSE_REFUND_CONFIRMATION_MODAL'
