import styled from 'styled-components'
import { ORDER_STATUSES_BG_COLOR, ORDER_STATUSES_INITIALS, ORDER_STATUSES_TITLES } from 'svr/constants'
import { OrderMethodStatuses } from '@sevenrooms/core/domain/constants'

const DropdownStatusOptionIcon = styled.span`
  font-size: 16px;
  font-family: Roboto, sans-serif;
  vertical-align: middle;
`

export const transformOrderStatusesToDropdown = (currentStatus, fulfillmentMethod) => {
  const choices = OrderMethodStatuses[fulfillmentMethod] || []
  if (!choices.includes(currentStatus)) {
    choices.push(currentStatus)
  }
  return choices.map(value => ({
    value,
    name: ORDER_STATUSES_TITLES[value],
    icon: <DropdownStatusOptionIcon>{ORDER_STATUSES_INITIALS[value]}</DropdownStatusOptionIcon>,
    iconStyle: {
      color: '#FFFFFF',
      background: ORDER_STATUSES_BG_COLOR[value],
      borderRadius: '50%',
      textAlign: 'center',
      height: 24,
      width: 24,
      marginRight: 8,
    },
  }))
}
