import { OrderedMap } from 'immutable'
import _ from 'lodash'
import moment from 'moment-timezone'
import money from 'money-math'
import * as ActualSlideoutActionTypes from 'mgr/actualslideout/actions/ActionTypes'
import { genericTagToDisplayTag } from 'mgr/lib/components/GenericTagsDropDown'
import * as ActionTypes from 'mgr/orderslideout/actions/ActionTypes'
import { camelCaseObject } from 'svr/common/ObjectUtils'
import { getVenueToday } from 'svr/common/TimeUtil'

const TODAY = 'Today'
const YESTERDAY = 'Yesterday'

const initialViewOrderState = {
  order: null,
  venueGroupClient: null,
  feedback: null,
  activityLog: [],
  internalComments: [],
  isLoadingActivityLog: false,
  isLoadingmessages: false,
  isSendingMessage: false,
  expandedRows: [],
  isModalSubmitting: false,
  notificationType: '',
  notificationTransactionId: null,
  notificationModalOpen: false,
  notificationEmail: '',
  refundingId: null,
  refundType: 'full',
  refundAmount: '',
  refundFull: '',
  refundDescription: '',
  refundSendNotification: true,
  deletingRequest: null,
  showRefundConfirmationModal: false,
  orderStatusVoidTransactionToRefund: null,
  formErrors: {},
  orderStatusChangeCallback: null,
  closeCallback: null,
}

const viewOrderReducer = (state = initialViewOrderState, action) => {
  const defaultEmail = state.actual?.venue_group_client.email_address || state.actual?.email_address || state.notificationEmail
  switch (action.type) {
    case ActionTypes.VIEW_ORDER: {
      return {
        ...state,
        viewVenue: action.venue,
        order: null,
        orderStatusChangeCallback: action.orderStatusChangeCallback,
        closeCallback: action.closeCallback,
      }
    }
    case ActionTypes.LOAD_ORDER_SUCCESS:
      const { order, venue_group_client, feedback, transactions, payout_profile } = action.data
      const clientTagsDisplay = _.map(_.values(venue_group_client.client_tags), genericTagToDisplayTag)
      const orderState = camelCaseObject(order)
      orderState.transactions = transactions
      orderState.venueGroupClient = venue_group_client
      const venueGroupClientState = camelCaseObject(venue_group_client)
      venueGroupClientState.clientTagsDisplay = clientTagsDisplay
      const feedbackState = camelCaseObject(feedback)
      return {
        ...state,
        order: orderState,
        venueGroupClient: venueGroupClientState,
        venuePayoutProfile: payout_profile,
        feedback: feedbackState,
        notificationEmail: venueGroupClientState.emailAddress,
      }
    case ActionTypes.PAYMENT_TOGGLE_DETAIL:
      let expanded = []
      if (state.expandedRows.indexOf(action.value) !== -1) {
        expanded = _.without(state.expandedRows, action.value)
      } else {
        expanded = _.concat(state.expandedRows, action.value)
      }
      return { ...state, expandedRows: expanded }
    case ActionTypes.PAYMENT_TOGGLE_MODAL_NOTIFICATION:
      return {
        ...state,
        notificationTransactionId: action.transaction?.id,
        notificationType: action.notificationType,
        notificationModalOpen: !!action.transaction?.id,
        notificationEmail: !action.transaction ? defaultEmail : action.transaction?.email || defaultEmail,
      }
    case ActionTypes.PAYMENT_NOTIFICATION_START:
      return { ...state, isModalSubmitting: true }
    case ActionTypes.PAYMENT_NOTIFICATION_SUCCESS:
      return {
        ...state,
        isModalSubmitting: false,
        notificationModalOpen: false,
      }
    case ActionTypes.PAYMENT_NOTIFICATION_FAIL:
      return { ...state, isModalSubmitting: false }
    case ActionTypes.PAYMENT_SET_REFUND_ID:
      const fullRefundAmount = money.floatToAmount(action.transaction?.amount_remaining_decimal)
      return {
        ...state,
        refundingId: action.transaction?.id,
        refundAmount: fullRefundAmount,
        refundFull: fullRefundAmount,
        notificationEmail: !action.transaction ? defaultEmail : action.transaction?.email || defaultEmail,
      }
    case ActionTypes.PAYMENT_REFUND_START:
      return { ...state, isModalSubmitting: true }
    case ActionTypes.REFUND_CHANGE_CHARGE_AMOUNT:
      return { ...state, refundAmount: action.value }
    case ActionTypes.REFUND_CHANGE_TYPE:
      return {
        ...state,
        refundType: action.value,
        refundAmount: action.value === 'full' ? state.refundFull : state.refundAmount,
      }
    case ActionTypes.REFUND_CHANGE_DESCRIPTION:
      return { ...state, refundDescription: action.value }
    case ActionTypes.REFUND_TOGGLE_NOTIFICATION:
      return { ...state, refundSendNotification: action.value }
    case ActionTypes.PAYMENT_REFUND_SUCCESS:
      const refundOrderState = state.order
      const transactionValues = _.values(refundOrderState.transactions)
      const transactionsUpdate = transactionValues.map(transaction => {
        if (transaction.id !== action.refundData.refunded_history_key) {
          return transaction
        }
        if (action.refundData.amount) {
          // eslint-disable-next-line no-param-reassign
          transaction.amount_remaining -= action.refundData.amount
          // eslint-disable-next-line no-param-reassign
          transaction.amount_remaining_decimal -= action.refundData.amount / 100
        }
        return transaction
      })
      refundOrderState.transactions = transactionsUpdate
      return {
        ...state,
        order: refundOrderState,
        refundingId: null,
        isModalSubmitting: false,
        transactions: transactionsUpdate,
        showRefundConfirmationModal: false,
        orderStatusVoidTransactionToRefund: null,
      }
    case ActionTypes.PUSH_PAYMENT_TRANSACTION:
      const paymentOrderState = state.order
      const _transactions = paymentOrderState.transactions
      _transactions.unshift(action.transaction)
      paymentOrderState.transactions = _transactions
      return { ...state, order: paymentOrderState }
    case ActionTypes.PAYMENT_REFUND_FAIL:
      return { ...state, isModalSubmitting: false, showRefundConfirmationModal: false, orderStatusVoidTransactionToRefund: null }
    case ActionTypes.PAYMENT_CHANGE_CHARGE_NOTIFICATION_EMAIL:
      return { ...state, notificationEmail: action.value }
    case ActionTypes.CHARGE_FORM_VALIDATED:
      return { ...state, formErrors: action.formErrors }
    case ActualSlideoutActionTypes.LOAD_ACTIVITY_LOG_START: {
      return { ...state, isLoadingActivityLog: true }
    }
    case ActualSlideoutActionTypes.LOAD_ACTIVITY_LOG_SUCCESS: {
      const { activityLog } = action
      if (!_.isEmpty(activityLog) && activityLog[0].conversation_id !== state.order.conversationId) {
        return {
          ...state,
          isLoadingActivityLog: false,
        }
      }
      // FIXME: Move this to the server side
      const todayStart = getVenueToday(state.viewVenue.timezone, state.viewVenue.startOfDayHour)
      const todayEnd = todayStart.endOf('day')
      const yesterdayStart = todayStart.subtract(1, 'days')

      let byDayMap = new OrderedMap()
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < activityLog.length; i++) {
        const logEntry = activityLog[i]
        const parsedDatetime = moment(logEntry.created)

        if (parsedDatetime >= todayStart && parsedDatetime <= todayEnd) {
          if (!byDayMap.has(TODAY)) {
            byDayMap = byDayMap.set(TODAY, [])
          }
          byDayMap.get(TODAY).push(logEntry)
        } else if (parsedDatetime >= yesterdayStart && parsedDatetime < todayStart) {
          if (!byDayMap.has(YESTERDAY)) {
            byDayMap = byDayMap.set(YESTERDAY, [])
          }
          byDayMap.get(YESTERDAY).push(logEntry)
        } else {
          const dayEntry = parsedDatetime.startOf('day').format('MMMM DD, YYYY')
          if (!byDayMap.has(dayEntry)) {
            byDayMap = byDayMap.set(dayEntry, [])
          }
          byDayMap.get(dayEntry).push(logEntry)
        }
      }
      return {
        ...state,
        isLoadingActivityLog: false,
        activityLog: Array.from(byDayMap.entries()),
      }
    }
    case ActionTypes.LOAD_MESSAGES_START: {
      return { ...state, isLoadingMessages: true }
    }
    case ActionTypes.LOAD_MESSAGES_SUCCESS: {
      const { messages } = action
      const external = []
      // eslint-disable-next-line no-plusplus
      for (let i = messages.length - 1; i >= 0; i--) {
        const message = messages[i]
        if ((message.category === 'ACTIVITY' && message.status !== 'ATTACHMENT') || _.isEmpty(message.text)) {
          continue
        }
        if (message.visibility.indexOf('ALL') > -1) {
          external.push(message)
        }
      }
      return {
        ...state,
        externalMessages: external,
        isLoadingMessages: false,
      }
    }
    case ActionTypes.SEND_MESSAGE_START: {
      return { ...state, isSendingMessage: true }
    }
    case ActionTypes.SEND_MESSAGE_SUCCESS: {
      const { message } = action
      let newMessageList
      if (message.visibility.indexOf('ALL') > -1) {
        newMessageList = [...state.externalMessages]
        newMessageList.push(message)
        return { ...state, externalMessages: newMessageList }
      }
      return state
    }
    case ActionTypes.ORDER_STATUS_CHANGE: {
      return {
        ...state,
        order: {
          ...state.order,
          status: action.value,
        },
      }
    }
    case ActionTypes.OPEN_REFUND_CONFIRMATION_MODAL: {
      return {
        ...state,
        showRefundConfirmationModal: true,
        orderStatusVoidTransactionToRefund: action.transactionToRefund,
      }
    }
    case ActionTypes.CLOSE_REFUND_CONFIRMATION_MODAL: {
      return {
        ...state,
        showRefundConfirmationModal: false,
        orderStatusVoidTransactionToRefund: null,
      }
    }
    case ActionTypes.CLOSE_SLIDEOUT:
      state.closeCallback?.(state.order)
      return {
        ...state,
      }
    default:
      return state
  }
}
export default viewOrderReducer
