import React from 'react'
import styled from 'styled-components'
import { SectionedCard, CardLabel } from 'mgr/orderslideout/components/view/ViewCommon'

const CardBodyText = styled.div`
  width: 334px;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 17px;
`

const OrderDetailsCard = ({ specialInstructions }) => (
  <SectionedCard>
    <CardLabel>DETAILS</CardLabel>
    {specialInstructions && <CardBodyText>{specialInstructions}</CardBodyText>}
  </SectionedCard>
)

export default OrderDetailsCard
