import _ from 'lodash'
import moment from 'moment-timezone'
import * as GlobalActions from 'mgr/lib/actions/GlobalActions'
import ActivityLogServices from 'mgr/lib/services/ActivityLogServices'
import MessagingServices from 'mgr/lib/services/MessagingServices'
import PaymentServices from 'mgr/lib/services/PaymentServices'
import { TransactionTypes, NotificationLevel, ENVIRONMENTS } from 'mgr/lib/utils/Constants'
import { setClipboard } from 'mgr/lib/utils/UXUtils'
import { batchActions } from 'svr/common/ReduxUtils'
import Gateway from 'svr/lib/Payments/Gateway'
import { OrderService } from '@sevenrooms/core/api'
import * as OrderActionTypes from './ActionTypes'
import * as SlideoutActions from './SlideoutActions'

const getOrderFail = () => ({ type: OrderActionTypes.LOAD_ORDER_FAIL })

export const tryShowOrder = (venueId, orderId, orderStatusChangeCallback, closeCallback) => dispatch => {
  dispatch({ type: OrderActionTypes.LOAD_ORDER_START })
  return Promise.all([OrderService.getOrder(venueId, orderId), dispatch(GlobalActions.ensureHaveBookingInfo(venueId))])
    .then(([response, venue]) => {
      dispatch({ type: OrderActionTypes.VIEW_ORDER, venue, orderStatusChangeCallback, closeCallback })
      if (response.status !== 200) {
        throw new Error('Failed to load Order.')
      }
      dispatch({
        type: OrderActionTypes.LOAD_ORDER_SUCCESS,
        data: response.data,
      })
      const { order } = response.data
      dispatch(batchActions([tryGetActivityLog(venueId, order.conversation_id), tryGetMessages(venueId, order.conversation_id)]))
      return response
    })
    .catch(err => dispatch(batchActions([dispatch(getOrderFail()), SlideoutActions.showNotificationError(err.message)])))
}

const getActivityLogBegin = () => ({
  type: OrderActionTypes.LOAD_ACTIVITY_LOG_START,
})

const getActivityLogSuccess = activityLog => ({
  type: OrderActionTypes.LOAD_ACTIVITY_LOG_SUCCESS,
  activityLog,
})

const getActivityLogFail = () => ({
  type: OrderActionTypes.LOAD_ACTIVITY_LOG_FAIL,
})

const tryGetActivityLog = (venueId, conversationId) => (dispatch, getState) => {
  dispatch(getActivityLogBegin())
  const errHandler = error =>
    dispatch(batchActions([getActivityLogFail(), SlideoutActions.showNotificationError(`Error getting activity log: ${error}`)]))
  return ActivityLogServices.fetchActivityLog(venueId, conversationId)
    .catch(errHandler)
    .then(activityLog => dispatch(getActivityLogSuccess(activityLog)))
}

const getMessagesBegin = () => ({ type: OrderActionTypes.LOAD_MESSAGES_START })

const getMessagesSuccess = messages => ({
  type: OrderActionTypes.LOAD_MESSAGES_SUCCESS,
  messages,
})

const getMessagesFail = () => ({ type: OrderActionTypes.LOAD_MESSAGES_FAIL })

const tryGetMessages = (venueId, conversationId) => (dispatch, getState) => {
  dispatch(getMessagesBegin())
  const errHandler = error =>
    dispatch(batchActions([getMessagesFail(), SlideoutActions.showNotificationError(`Error getting conversation history: ${error}`)]))
  return MessagingServices.fetchMessages(venueId, conversationId, errHandler).then(messages => dispatch(getMessagesSuccess(messages)))
}

export const tryPutExternalMessage = message => (dispatch, getState) =>
  dispatch(tryPutMessage(message, undefined, undefined, undefined, undefined, 'a', 'Reservation', moment()))

const getMessagePutBegin = () => ({ type: OrderActionTypes.SEND_MESSAGE_START })

const getMessagePutFail = () => ({ type: OrderActionTypes.SEND_MESSAGE_FAIL })

const getMessagePutSuccess = message => ({
  type: OrderActionTypes.SEND_MESSAGE_SUCCESS,
  message,
})

const tryPutMessage =
  (message, systemMessage, category, status, attachments, visibility, transactionType, date) => (dispatch, getState) => {
    const state = getState()
    const { order } = state.viewOrderState
    const { id, venueId, conversationId } = order
    const errHandler = error =>
      dispatch(batchActions([getMessagePutFail(), SlideoutActions.showNotificationError(`Error sending message: ${error}`)]))
    dispatch(getMessagePutBegin())
    return MessagingServices.createMessage(
      venueId,
      conversationId,
      message,
      systemMessage,
      category,
      status,
      attachments,
      visibility,
      transactionType,
      date,
      id,
      errHandler
    ).then(message => {
      dispatch(getMessagePutSuccess(message))
    })
  }

export const togglePaymentDetail = value => ({
  type: OrderActionTypes.PAYMENT_TOGGLE_DETAIL,
  value,
})
const notificationBegin = () => ({
  type: OrderActionTypes.PAYMENT_NOTIFICATION_START,
})

export const sendNotification = (isRefund, billingHistory) => (dispatch, getState) => {
  dispatch(notificationBegin())

  const state = getState()
  const venueId = state.viewOrderState.viewVenue.id
  const { venueGroupClient } = state.viewOrderState
  const clientId = venueGroupClient.id
  const isEmailEditable = venueGroupClient.is_email_address_editable
  const { order } = state.viewOrderState
  const { actualId } = order
  const orderId = order.id
  const historyId = billingHistory && billingHistory.id ? billingHistory.id : state.viewOrderState.notificationTransactionId
  const email = isEmailEditable ? state.viewOrderState.notificationEmail : ''
  const isInfoRequest = false
  let transaction
  if (billingHistory) {
    transaction = billingHistory
  } else {
    const transactionValues = _.values(order.transactions)
    // eslint-disable-next-line prefer-destructuring
    transaction = _.filter(transactionValues, t => t.id === historyId)[0]
  }
  const { last_4, brand, amount, transaction_id } = transaction

  const errHandler = error =>
    dispatch(batchActions([notificationFail(), SlideoutActions.showNotificationError('Could not send notification')]))

  return PaymentServices.sendNotification(
    venueId,
    actualId,
    clientId,
    transaction_id,
    historyId,
    email,
    amount,
    last_4,
    brand,
    isRefund,
    isInfoRequest,
    orderId,
    errHandler
  ).then(result => dispatch(batchActions([notificationSuccess(result), notificationSuccessBanner()])))
}

const notificationSuccess = value => ({
  type: OrderActionTypes.PAYMENT_NOTIFICATION_SUCCESS,
  value,
})

const notificationFail = value => ({
  type: OrderActionTypes.PAYMENT_NOTIFICATION_FAIL,
  value,
})

const notificationSuccessBanner = () =>
  SlideoutActions.showNotificationBanner({
    message: 'Notification sent',
    level: NotificationLevel.SUCCESS,
    visible: true,
  })

export const showRefundFromWrongAccountNotification = () =>
  SlideoutActions.showNotificationBanner({
    message:
      'It looks like this charge was initiated from a different stripe account. If you wish to refund, please do so via your Stripe portal directly.',
    level: NotificationLevel.ERROR,
    visible: true,
  })

export const setRefundId = transaction => ({
  type: OrderActionTypes.PAYMENT_SET_REFUND_ID,
  transaction,
})

export const copyLink = link => {
  setClipboard(link)
  return SlideoutActions.showNotificationBanner({
    message: 'Link copied',
    level: NotificationLevel.SUCCESS,
    visible: true,
  })
}

export const toggleNotificationModal = (transaction, notificationType) => ({
  type: OrderActionTypes.PAYMENT_TOGGLE_MODAL_NOTIFICATION,
  transaction,
  notificationType,
})

const refundBegin = () => ({ type: OrderActionTypes.PAYMENT_REFUND_START })

const refundFail = value => ({
  type: OrderActionTypes.PAYMENT_REFUND_FAIL,
  value,
})

const refundSuccess = refundData => ({
  type: OrderActionTypes.PAYMENT_REFUND_SUCCESS,
  refundData,
})

const refundSuccessBanner = () =>
  SlideoutActions.showNotificationBanner({
    message: 'Refund successful',
    level: NotificationLevel.SUCCESS,
    visible: true,
  })

const processRefund = (refundingId, refundAmount, refundDescription, refundSendNotification) => (dispatch, getState) => {
  dispatch(refundBegin())

  const state = getState()
  const { venueGroupClient } = state.viewOrderState
  const clientId = venueGroupClient.id
  const { order } = state.viewOrderState

  const transactionValues = _.values(order.transactions)
  const transaction = _.filter(transactionValues, t => t.id === refundingId)[0]
  const refundAmountInCents = refundAmount ? Math.round(refundAmount * 100) : 0

  const gateway = new Gateway(state.viewOrderState.viewVenue, state.appState.userDomain.env === ENVIRONMENTS.PRODUCTION, 'vms')

  return gateway
    .refund({
      historyId: transaction.id,
      clientId,
      amount: refundAmountInCents,
      reason: refundDescription,
    })
    .then(result => {
      if (result.status !== 200) {
        throw result
      }
      dispatch(
        batchActions([
          refundSuccess(result.data.result),
          refundSuccessBanner(),
          pushTransaction(createTransaction(result.data.result, TransactionTypes.REFUND)),
        ])
      )
      return result
    })
    .then(result => {
      if (!refundSendNotification) {
        return
      }
      dispatch(sendNotification(true, result.data.result))
    })
    .catch(result => {
      dispatch(batchActions([refundFail(), SlideoutActions.showNotificationError('Encountered an error while attempting to refund.')]))
    })
}

export const submitRefund = () => (dispatch, getState) => {
  const state = getState()
  const { refundingId, refundAmount, refundDescription, refundSendNotification } = state.viewOrderState
  dispatch(processRefund(refundingId, refundAmount, refundDescription, refundSendNotification))
}

export const submitCustomRefund = (refundingId, refundAmount, refundDescription, refundSendNotification) => dispatch => {
  dispatch(
    batchActions([processRefund(refundingId, refundAmount, refundDescription, refundSendNotification), closeRefundConfirmationModal()])
  )
}

const pushTransaction = transaction => ({
  type: OrderActionTypes.PUSH_PAYMENT_TRANSACTION,
  transaction,
})

const createTransaction = (data, type) => {
  switch (type) {
    case TransactionTypes.REFUND:
      let chargedFormatted
      if (data.charged_formatted) {
        chargedFormatted = data.charged_formatted
      } else {
        const chargedMoment = moment(data.charged)
        chargedFormatted = chargedMoment.format('MMMM DD, hh:mm A')
      }
      return {
        transaction_type: type,
        transaction_id: data.transaction_id,
        charged_formatted: chargedFormatted,
        brand: data.brand,
        last_4: data.last_4,
        card_id: data.card_id,
        id: data.id,
        notes: data.notes,
        base_amount_formatted: data.base_amount_formatted,
        amount_formatted: data.amount_formatted,
        amount_decimal: data.amount_decimal,
        gratuity: data.gratuity,
        additional_fee: data.additional_fee,
        additional_fee_tax: data.additional_fee_tax,
        gratuity_formatted: data.gratuity_formatted,
        tax: data.tax,
        tax_decimal: data.tax_decimal,
        tax_formatted: data.tax_formatted,
        additional_fee_formatted: data.additional_fee_formatted,
        additional_fee_tax_formatted: data.additional_fee_tax_formatted,
      }
    default:
      return {}
  }
}

const statusChangeSuccess = () =>
  SlideoutActions.showNotificationBanner({
    message: 'Status successfully changed',
    level: NotificationLevel.SUCCESS,
    visible: true,
  })

export const changeOrderStatus = (orderId, status) => (dispatch, getState) => {
  const state = getState()
  const { venueId, status: oldStatus } = state.viewOrderState.order
  dispatch(orderStatusChange(status))
  if (status === 'VOID') {
    dispatch(openFullRefundConfirmationModal())
  }
  OrderService.changeOrderStatus(venueId, orderId, status)
    .then(() => {
      dispatch(statusChangeSuccess())
      state.viewOrderState.orderStatusChangeCallback?.(orderId, status)
    })
    .catch(() => {
      dispatch(
        batchActions([
          orderStatusChange(oldStatus),
          SlideoutActions.showNotificationError('Encountered an error while attempting to change order status.'),
        ])
      )
    })
}

export const orderStatusChange = value => ({
  type: OrderActionTypes.ORDER_STATUS_CHANGE,
  value,
})

const openFullRefundConfirmationModal = () => (dispatch, getState) => {
  const state = getState()
  const { transactions } = state.viewOrderState.order
  const transactionToRefund = _.find(transactions, obj => obj.is_refund === false && obj.amount_remaining_decimal > 0)
  if (transactionToRefund) {
    dispatch({ type: OrderActionTypes.OPEN_REFUND_CONFIRMATION_MODAL, transactionToRefund })
  }
}

export const closeRefundConfirmationModal = () => ({ type: OrderActionTypes.CLOSE_REFUND_CONFIRMATION_MODAL })

export const changeRefundNotification = value => ({
  type: OrderActionTypes.REFUND_TOGGLE_NOTIFICATION,
  value,
})

export const changeRefundType = value => ({
  type: OrderActionTypes.REFUND_CHANGE_TYPE,
  value,
})

export const changeRefundAmount = value => ({
  type: OrderActionTypes.REFUND_CHANGE_CHARGE_AMOUNT,
  value,
})

export const changeRefundDescription = value => ({
  type: OrderActionTypes.REFUND_CHANGE_DESCRIPTION,
  value,
})

export const changeNotificationEmail = value => ({
  type: OrderActionTypes.PAYMENT_CHANGE_CHARGE_NOTIFICATION_EMAIL,
  value,
})

export const chargeFormValidated = formErrors => ({
  type: OrderActionTypes.CHARGE_FORM_VALIDATED,
  formErrors,
})
