import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { applyMiddleware, createStore, compose } from 'redux'
import { enableBatching } from 'redux-batched-actions'
import thunk from 'redux-thunk'
import * as GlobalActions from 'mgr/lib/actions/GlobalActions'
import * as SlideoutActions from './actions/SlideoutActions'
import * as ViewOrderActions from './actions/ViewOrderActions'
import App from './containers/App'
import rootReducer from './reducers/CombineReducer'
import { svrExport } from '@sevenrooms/core/ui-kit/utils'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(enableBatching(rootReducer), composeEnhancers(applyMiddleware(thunk)))

// Subscribe before initialize gets called, so that we're guaranteed to process user domain venues
Pmp.Manager.Global.subscribeOnUserDomainVenuesLoad(() => store.dispatch(GlobalActions.userDomainVenuesLoaded()))

ReactDOM.render(
  <Provider store={store}>
    <App globalInit={globalInit} />
  </Provider>,
  document.getElementById('sevenrooms-order-slideout')
)

export const viewOrder = (venueId, orderId, orderStatusChangeCallback, closeCallback) =>
  store.dispatch(ViewOrderActions.tryShowOrder(venueId, orderId, orderStatusChangeCallback, closeCallback))

svrExport('OrderSlideout', 'viewOrder', viewOrder)

const closeSlideout = () => store.dispatch(SlideoutActions.closeSlideout())
svrExport('OrderSlideout', 'closeSlideout', closeSlideout)
